module.exports = {

    author: "@hantermigers",
    siteTitle: "Hanter:Hani Tri Agusnurtofiq",
    siteShortTitle: "Hanter", // Used as logo text in header, footer, and splash screen
    siteDescription: "An private website by Hani Tri Agusnurtofiq.",
    siteUrl: "https://hanter.my.id/",
    siteLanguage: "en_US",
    siteIcon: "src/content/favicon.png", // Relative to gatsby-config file

    splashScreen: true, // Set this to true if you want to use the splash screen

    // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
    // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
    mediumRssFeed: "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40hantermigers",
    shownArticles: 3,
    
    // There are icons available for the following platforms: 
    // Medium, GitHub, LinkedIn, XING, Behance
    socialMedia: [
        {
            name: "Linkedin",
            url: "https://www.linkedin.com/in/hani-tri-agusnurtofiq-77862a1a4/"
        },
        {
            name: "Medium",
            url: "https://medium.com/@hantermigers"
        },
        {
            name: "Github",
            url: "https://medium.com/@hantermigers"
        },
        {
            name: "Xing",
            url: "https://medium.com/@hantermigers"
        },
    ],
  
    navLinks: {
        menu: [
            {
                name: "Publications",
                url: "/#articles",
            },
            {
                name: "Skills",
                url: "/#interests",
            },
            {
                name: "Projects",
                url: "/#projects",
            },
        ],
        button: {
            name: "Contact Me",
            url: "/#contact",
        }
    },

    footerLinks: [
        {
            name: "Privacy",
            url: "/privacy"
        },
        {
            name: "Imprint",
            url: "/imprint"
        }
    ]
}